










/*************************************************
Контакты
*************************************************/
.contacts{
	padding-bottom: 20px;


  img.map{
    width: 100%;
    max-width: 1000px;
    display: block;
    margin: 30px auto;
  }
}


.contacts .fa {
    font-size: 18px;
    width: 24px;
}

.contacts .hide-fa{
	opacity: 0;
}
