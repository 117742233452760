/*************************************************
Хлебные крошки
Пагинации
*************************************************/














/*************************************************
Хлебные крошки
*************************************************/
.bread-crumbs{
	margin-top: 10px;
	margin-bottom: 10px;
}

.bread-crumbs a{
	color: black;
	/*color: #6f1a0f; цвета для анны*/
	text-decoration: underline;
}

.bread-crumbs a:hover{
	/*color: brown;*/
}



/*************************************************
Пагинации
*************************************************/

/*для спецкаталогов*/
	.custom-pagination{
		margin: 20px 0;
		text-align: center;
	}

	.custom-pagination .page-numbers{
		border: 1px solid #262626;
		color: #1e1e1e;
		background-color: #DBD5D1;
	}

	.custom-pagination a{
		padding: 5px 10px;
		color: black;
	}

	.custom-pagination .page-numbers.dots {
	    padding: 5px 10px;
	}

	.custom-pagination a:hover{
		padding: 5px 10px;
		color: white;
		background-color: black;
		text-decoration: none;
	}
	.custom-pagination a:focus{
		text-decoration: none;
		outline:none;
	}

	.custom-pagination .current{
		padding: 5px 10px;
		color: #000;
		background-color: #959595;
	}


	/*чтоб не ломалась верстка - скрывать page-numbers в xs (они отображаются все при любом количестве)*/
	/*xs*/
	@media screen and (max-width: 768px) {
		.page-numbers{
			display: none;
		}
		.page-numbers.next, .page-numbers.prev{
			display: inline-block;
			background-color: hsl(21, 78%, 71%);
		}
	} 


/*штатная - для выдачи фильтров*/
	nav.navigation{
		display: block;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.navigation .screen-reader-text{
		display: none;
	}

	.navigation .nav-links a{
		display: inline-block;
		border: 1px solid brown;
		color: brown;
		background-color: #FED8C3;
		padding: 5px 10px;
	}

	.navigation .nav-links a:hover{
			padding: 5px 10px;
			color: white;
			background-color: brown;
			text-decoration: none;
	}










