
@import '../common/my-mixins';
@import '../common/common-components';












/*************************************************
To Common Styles
*************************************************/


$xs : 768;
$sm : 992px;
$md : 1200px;




















/*************************************************
Header
*************************************************/
.header{
  // box-shadow: -1px 4px 17px grey;
  min-width: 320px;
}



.top-line {
  padding-top: 1px;

  &__col-delimiter{
    margin-top: 30px;
    border-right: 1px solid #dce1ef;
    height: 105px;
  }

  &__logo {
    display: block;
    // margin-top: 11px;
    margin-bottom: 15px;
  }

  &__slogan {
    color: $blue;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;

    padding-bottom: 15px;
    margin-left: 10px;
    position: relative;

    &:after{
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;

      width: 250px;
      height: 1px;
      background-color: #c9d1e6;
    }

  }

  &__adress {
    padding-top: 10px;
    font-size: 13px;
    color: #333333;
    margin-left: 10px;
    // padding-bottom: 10px;
  }


  &__icon {
    width: 25px;
    height: 25px;
    display: inline-block;
    // border: 1px solid red;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    position: relative;
    top: 5px;

    &--phone1{
    background-image: url('../img/icon__phone.png');
    }

    &--phone2{
      background-image: url('../img/icon__phone2.png');

    }

    &--mail{
      background-image: url('../img/icon__mail.png');
    }


  }
  &__phones {
    padding-left: 10px;
    a{
      display: block;
      font-size: 16px;
      color: #333333;
      text-decoration: none;

      &:hover{
        color: blue;
      }
    }
  }


  &__mail {
    padding-top: 30px;
    a{
      display: inline-block;
      font-size: 16px;
      color: #05017a;
      text-decoration: underline; 
      padding-bottom: 19px;

      &:hover{
        color: blue;
      }     
    }
  }

  &__btn {
    margin-left: 28px;

    background-color: #ff5500;
    border-radius: 4px;
    width: 150px;
    height: 35px;
    line-height: 35px;
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    color: white;
    cursor: pointer;

    &:hover{
      background-color: black;
    }
    
  }
}





.menu {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 10px 0;
  background-image: linear-gradient($blue, darken($blue, 5%));
  position: relative;
  // z-index: 1000;

  &__item {
    list-style: none;
  }

  &__link {
    padding: 0 40px 2px;
    color: white;
    @include font-o;
    font-size: 25px;

    &:hover{
      background-color: white;
      color: #333;
      text-decoration: none;
    }
  }

  &__mob-btn{
    color: white;
    @include font-o;
    font-size: 25px;
    cursor: pointer;    
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1200px){
  .top-line {

  &__logo {
    margin-bottom: 20px;
  }

  &__col-delimiter {
  }

  &__slogan {
    margin-left: 15px;
    font-size: 12px;
  }

  &__adress {
    margin-left: 15px;
    padding-bottom: 10px;
  }

  &__phones a {
    font-size: 13px;
  }

  &__icon {

    &--phone1 {
    }

    &--phone2 {
    }

    &--mail {
    }
  }

  &__mail a {
    font-size: 13px;
  }

  &__btn {
  }
}

} // end m-max(1200)

@include m-max(992){
  .top-line {
    padding: 0;

  &__logo {
    display: block;
    margin: 0 auto;
    padding: 0;
    text-align: center;
  }

  &__col-delimiter {
    padding: 0;
    margin: 0;
    border-right: none;
    height: auto;
  }

  &__slogan {
    text-align: center;
    padding-top: 10px;
    br{
      display: none;
    }
    &:after{
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  &__adress {
    text-align: center;
    padding-bottom: 10px;
    br{
      display: none;
    }
  }

  &__phones {
    text-align: center;
    padding: 0;
    margin: 0;
  }

  &__icon {
  }

  &__mail {
    text-align: center;
    padding: 0;
    margin: 0;
  }

  &__btn {
    display: block;
    margin: 0 auto;
    margin-bottom: 10px;
  }
}

} // end m-max(1200px)




@include m-max(750){
  .menu {
    background-image: linear-gradient(darken($blue, 5%), darken($blue, 5%));
    display: block;

    &__mob-btn{
      display: block;
      text-align: center;
      padding-bottom: 10px;
    }

    &__item {
      display: block;
      display: none;
      text-align: center;

      &:nth-child(2) {
        border-top: 1px solid white;
      }
    }

    &__link {
      text-align: center;
    }
  }

}











/*************************************************
Slider
*************************************************/
.home-page-slider {
  position: relative;
  z-index: 0;
  margin-bottom: 0 !important;

  &__item {
    // height: 300px;
    width: 100%;
    // background-size: cover;
    // background-position: 50% 50%;
    // background-repeat: no-repeat;
    
    img{
      width: 100%;
    }
  }


  // пагинация слайдера
  .slick-dots{
    text-align: right;
    bottom: 30px ;
    right: 30px;
    z-index: 1000;
  }
  .slick-dots li{
    background-color: grey;
  }
  .slick-dots li.slick-active{
    background-color: $blue;
  }
  .slick-dots li button:before{
    color: transparent !important;
  }


  @include m-max(768){
    .slick-dots{
      bottom: 5px ;
      right: 5px;
    }
    .slick-dots li{
      width: 10px;
      height: 10px;
    }    
  } // end m-max(768)

}


  











/*************************************************
About
*************************************************/
.about {
  padding: 30px 0 0;

  &__item {
    text-align: center;
  }

  &__item-icon {
    width: 100%;
    max-width: 128px;
    margin-bottom: 10px;
  }

  &__item-title {
    @include font-o;
    font-size: 18px;
    margin-bottom: 10px;
    color: $blue;
  }

  &__item-descr {
    margin-bottom: 30px;
  }

}











/*************************************************
Promotext
*************************************************/

.promotext {
  background-image: url(../img/bg__promotext.jpg);
  background-size: cover;
  background-position: 50% 50%;
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-shadow: 0px 0px 3px black;
  position: relative;

  &:before{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(1,1,1, .5);
  }

  &__quote{
    display: block;
    margin: 30px auto;
  }

  &__inner {
    text-align: center;
    // background-color: $blue;
    // padding: 10px 20px;
    // border-radius: 10px;
    // color: white;
    // font-size: 18px;
  }

  padding-bottom: 30px;
}












/*************************************************
Footer
*************************************************/


.footer {
  // margin-top: -30px;
  font-size: 16px;

  @include m-max(1200){
    font-size: 14px;
  } // end m-max(1200)


  &--border{
    border-top: 15px solid $blue;
  }



}
.footer-menu {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  padding-top: 30px;

  &__item {
    list-style: none;
    padding: 0 30px;
    // font-size: 12px;

    @include m-max(1200){
      padding: 0 20px;
    }
    @include m-max($xs){
      padding: 0 5px;
    }
  }

  &__link {
    color: #333;
  }
}

.footer-contact {
  padding-top: 30px;
  text-align: center;
  a{
    color: #333;
    &:hover{
      text-decoration: underline;
    }
    white-space: nowrap;

  }
  br{
    @include m-min(768){
      display: none;
    } // end m-max(768)
  }
}

.footer-copyright{
  padding: 20px 0;
  text-align: center;
}


// модальное окно
.modal-overlay {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: auto;
}

.modal {
  position: relative;
  max-width: 500px;
  margin: auto;
  top: 70px;
  left: 0;
  right: 0;
  background-color: white;
  padding: 40px 40px;
  margin-bottom: 50px;
}

@media screen and (max-width: 768px) {
  .modal {
    top: 70px;
    bottom: auto;
    margin-left: 20px;
    margin-right: 20px;
    transform: translate(0, 0);
    padding: 15px 15px;
    min-height: auto;
  }
}

.modal__close-btn {
  position: absolute;
  top: -50px;
  right: -8px;
  width: 40px;
  height: 40px;
  background-image: url('../img/btn__modal-close.png');
  background-size: contain;
  cursor: pointer;
}

.modal__close-btn:hover {
  background-image: url('../img/btn__modal-close--hover.png');
}




// форма в модальке
.form-modal {

  &__title {
    font-weight: bold;
  }

  &__input {
    display: block;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 30px;
    padding: 5px 10px;
    border: 1px solid lightgrey;

    &:focus{
      border: 1px solid $blue;
    }
  }

  &__textarea {
    display: block;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 30px;
    padding: 5px 10px;
    border: 1px solid lightgrey;

    &:focus{
      border: 1px solid $blue;
    }
  }

  &__submit {
    display: block;
    margin: 0 auto;
    cursor: pointer;
    width: 150px;
    height: 40px;
    line-height: 40px;
    text-transform: uppercase;
    background-color: $blue;
    color: white;
    border: none;
    text-align: center;

    &:hover{
      background-color: black;
    }
  }
}
