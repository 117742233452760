







/*************************************************
Энвира
*************************************************/

.envirabox-wrap .envirabox-title-float-wrap .child {
    /*display: none !important;*/
}

/*глюки новой Envira*/

.envira-gallery-wrap .envira-gallery-public.envira-gallery-css-animations .envira-gallery-item img{
	opacity: 1;
}