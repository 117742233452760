@import '../common/my-mixins';
@import '../common/common-components';



.catalog {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;

  &__item {
    width: 300px;
    height: 300px;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    padding: 20px 20px;
    margin: 10px 10px;

    // border: 1px solid #000;
    box-shadow: 0 0 10px rgba(0,0,0,.3);
    border-radius: 10px;
  }

  &__img-link {
    display: inline-block;
    margin-bottom: 10px;
  }

  &__item-img {
    width: 180px;
    height: 180px;
    object-fit: contain;
  }

  &__item-btn {
    display: block;
    padding: 5px 10px;
    background-image: url('../img/btn__bg.gif');
    border-radius: 10px;
    border: 1px solid #dae3e6;

    &:hover, &:focus{
      text-decoration: none;
      cursor: pointer;
      background-color: $blue;
      color: white;
      border-color: $blue;
      background-image: none;
    }
  }
}
