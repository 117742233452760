/*************************************************
КОНТЕНТ ИЗ РЕДАКТОРА:

	Сам блок контента (отступы и т.п.)
	Типографика: 
		заголовки (цвета!)
		ссылки (цвета!)
		текст
		цитаты
	Списки (маркеры и их цвета)
	Изображения из медиафайлов
	Таблицы (цвета)

	Диз. заголовки для организации демо-контента

*************************************************/



// КОНФИГ
$color-akcent: $blue;


$heading-color:$color-akcent;
$heading-font: 'Oswald', 'Tahoma', sans-serif !important;

$text-color: #333;
$link-color: $blue;

$table__heading-color:grey;
$table__hover-color:yellow;
$table__border-color:#000;






/*************************************************
Типографика - цвета для Анны и др.
заголовки, ссылки, текст, цитаты
*************************************************/
.inner-text{
  padding-bottom: 30px; 

	/* заголовки - общие стили */
		 h1 {
		     font-family: $heading-font;
		     font-size: 30px;
		     text-align: center;
		     padding: 20px 20px;
		     color: $heading-color;
		 }
		 h2 {
		 	 font-size: 26px;
		     text-align: center;
		     font-family: $heading-font;
		     color: $heading-color;
		 }
		 h3 {
		 	 font-size: 26px;
		     text-align: center;
		     font-family: $heading-font;
		     color: $heading-color;
		 }
		 h4 {
		     font-size: 20px;
		     text-align: center;
		     font-family: $heading-font;
		     color: $heading-color;
		 }
		 h5 {
		     font-size: 18px;
		     text-align: center;
		     font-family: $heading-font;
		     color: $heading-color;
		 }
		 h6 {
		     font-size: 16px;
		     text-align: center;
		     font-family: $heading-font;
		     color: $heading-color;
		 }


	/* заголовки - декоративные стили под проект*/
		 h1 {
        position: relative;
        padding-bottom: 15px;
        margin-bottom: 30px;
        text-align: center;

        &:after{
          content: '';
          position: absolute;
          width: 150px;
          height: 2px;
          bottom: 1px;

          left: 50%;
          transform: translate(-50%, 0);    
          background-color: $blue;
        }
		 }
		 h2 {
		 }
		 h3 {
		 }
		 h4 {
		 }
		 h5 {
		 }
		 h6 {
		 }


	/*ссылки*/
		a{
			color:$link-color;
			text-decoration: underline;
		}
		a:hover{
			text-decoration: underline;
			color: blue;
		}
		a:visited{

		}


	/*текст*/
		&{
			color: $text-color;
		}

	/*цитаты*/
		blockquote{
			background-color: #F2F2F2;
			padding: 20px 20px 20px 70px;
			margin-top: 0px;
			margin-bottom: 0px;
			position: relative;
		}

		blockquote:before{
			position: absolute;
			content: '';
			width: 23px;
			height: 19px;
			background-image: url(../../img/cite-wp-content.png);
			left: 25px;
			top: 25px;
		}

		/*xs*/ 
		@media screen and (max-width: 768px) {
			blockquote{
				padding: 20px;
			}
			blockquote:before{
				display: none;
			}
		} 

} // end .inner-text


/*************************************************
Списки
*************************************************/

.inner-text{


/*списки - отступы*/
	ol{
		margin-left: 30px;
	}


/*маркированный список - произвольный символ и цвет
	символы
		http://saney.ru/tools/symbols.html
		http://paulradzkov.com/2012/html-entities_and_utf_codes/
	*/
	ul{
		
	}
	ul li{
		list-style-type: none;
		padding-left: 31px; /*отступ слева*/
		position: relative;
	}
	ul li:before{
		content:'◆'; 
		position: absolute;
		left:15px;
		color: inherit; /*inherit - по умолчанию*/
	}

// нумерованный список
	ol{
		margin-left: 30px;
	}
	
	// стилизация нумерованного списка
	// ol{
	// 	counter-reset: preview-ol;
	// }

	// ol li{
	// 	list-style: none;
	// }


	// ol li:before{
	// 	content: counter(preview-ol);
	// 	counter-increment: preview-ol;
	// 	background: #666;
	// 	color: #fff;
	// 	border-radius: 13px;
	// 	width: 26px;
	// 	height: 26px;
	// 	display: inline-block;
	// 	text-align: center;
	// 	margin-right: 20px;
	// }

} // end .inner-text


/*************************************************
Изображения из медиафайлов
*************************************************/
.inner-text{



	img{
		max-width: 300px;
		height: auto;
		box-shadow: 2px 2px 4px grey;
		border-color: red;
	}

/*выравнивание и обтекание*/
	img.alignleft {
	    float: left;
	    margin-bottom: 10px ;
	    margin-right: 20px ;
	}

	img.alignright {
	    float: right;
	    margin-bottom: 10px;
	    margin-left: 10px;
	}

	img.aligncenter {
		clear: both;
		display: block;
		margin: 0 auto;
	}
	


/*размеры изображений*/
	img.size-full{
		max-width: 691px !important;
		width: 100%;
	}

	img.size-large {
		max-width: 700px !important;
		width: 100%;
	}

	img.size-medium{
		max-width: 300px !important;
		width: 100%;
	}

	img.size-thumbnail{
		max-width: 150px !important;
		object-fit: cover;
	}


/*изображения с подписями*/

	figure.wp-caption{
		padding: 10px;
		box-shadow: 2px 2px 4px grey;
		margin-top: 6px;
	}

	figure img{
		box-shadow: none; 
	}

	figcaption.wp-caption-text{
		text-align: center;
		font-style: italic;
		padding: 5px 10px 5px;
		margin: 0 auto;
		max-width: 300px;
	}

	figure.alignleft{
	    float: left;
	    margin-bottom: 10px !important;
	    margin-right: 20px !important;	
	}

	figure.alignright {
	    float: right;
	    margin-bottom: 10px;
	    margin-left: 10px;
	}

	figure.aligncenter{
		display: block;
		margin: 0 auto;
	}


	/*изображения из галлереи*/
	.gallery img{
		box-shadow: 2px 2px 4px red;
	}


} // end .inner-text


/*************************************************
Таблицы
*************************************************/
.inner-text{
	

	

	table {
	    border-collapse: collapse;
	    border: 1px solid $table__border-color;
	    box-shadow: 3px 3px 9px grey;
	    margin: 10px auto 30px;
	}

	td {
	    border: 1px solid $table__border-color;
	    padding: .3em .5em;
	    text-align: right;
	}

	td:first-child {
	    text-align: left; // столбец со статьями - выравнивание по левому краю
	}

	// :hover эффект строки
	tr:hover {
	    background-color: $table__hover-color;
	    cursor: default;
	    /*color: black;*/
	}

	// строка заголовков
	tr:first-child {
	    font-weight: bold;
	    color: white;
	    background-color: brown;
	    background-image: linear-gradient(to bottom, $table__heading-color, darken($table__heading-color, 10%));	
	}




} // end .inner-text



/*******************************************************
Заголовок для организации демо-контента
********************************************************/
h1.demo{
	font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
	text-align: left;
	border-bottom: 2px solid #000;
	margin-top: 50px;
	color: black;
}
h1.demo:after{
	position: static;
	width: auto;
	height: auto;
	border-bottom: none;
	margin-left: 0;
}


