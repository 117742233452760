/*************************************************
Переменные SCSS
Сетка - модифицации Bootstrap
Section - блоки разным цветом

Шрифт по умолчанию
Заголовок - heading
Типографика

Селект под цвет сайта
Кнопки 

Адаптивка - сверхмалые экраны
Шрифт по умолчанию
Выкл. синюю границу в Chrome и Firefox (Кроссбрауз.)
Выкл. подчеркивания Bootstap
*************************************************/














/*************************************************
Variables
*************************************************/
$xs: 768;
$sm: 992;
$md: 1200;

$blue: #05017A;
$grey: #f4f4f4;
$white: #FFF;




/*************************************************
Сетка
*************************************************/
.container{
  min-width: 320px;
}













/*************************************************
Блоки с цветом
*************************************************/


.section{
  background-color: $white;
  padding-bottom: 30px;
  padding-top: 30px;
  position: relative;

  &:after{
    content: '';
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translate(-50%, 0);
    @include arrow(30, 20, $white, b);
    z-index: 1;
  }

  &--grey{
    background-color: $grey;
    &:after{
      @include arrow(30, 20, $grey, b);
    }    
  }

  &--white{
    background-color: $white;
    &:after{
      @include arrow(30, 20, $white, b);
    }    
  }

  &--no-arrow{
    &:after{
      display: none;
    }
  }


}
.section:nth-child(odd){
  background-color: $grey;

  &:after{
    @include arrow(30, 20, $grey, b);
  }
}





/*************************************************
Шрифт по умолчанию
*************************************************/

body{
  font-family: 'Open Sans', 'Arial', sans-serif;
  font-size: 18px;
}


@mixin font-o() {
  font-family: 'Oswald', 'Tahoma', sans-serif !important;
}



/*************************************************
Заголовок - title
*************************************************/
.title{
  @include font-o;
  color: $blue !important;
  font-size: 30px;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 30px;
  text-align: center;

  &:after{
    content: '';
    position: absolute;
    width: 150px;
    height: 2px;
    bottom: 1px;

    left: 50%;
    transform: translate(-50%, 0);    
    background-color: $blue;
  }
}


/*************************************************
Типографика
*************************************************/

/*цвета для анны*/
h1, h2, h3, h4, h5, h6{
	/*color: hsl(283, 75%, 24%);*/
	/*text-shadow:0px 1px 2px grey;*/
	/*margin-top: 0;*/
}
a{
	/*color: #731697;*/
	/*outline:none;*/
}
a:hover{
	/*color: #731697;*/
	/*text-decoration: none;*/
}



h1.center, h2.center, h3.center{
	/*text-align: center;*/
}












/*************************************************
Селект под цвет сайта
*************************************************/
::-moz-selection{
	background-color: #00C6FF;
	color: black;
}
::selection{
	background-color:  #00C6FF;
	color: black;
}





/*************************************************
Кнопки 
*************************************************/















/*************************************************
Адаптивка - сверхмалые экраны
*************************************************/
.container{
	min-width: 300px;
}










/*************************************************
Выкл. синюю границу в Chrome и Firefox (Кроссбрауз.)
---
переоопределяем стили браузера по умолчанию
*************************************************/
input, textarea { 
	outline: none; 
}

input:active, 
textarea:active { 
	outline: none; 
}

:focus { 
	outline:none;
}

a:focus{
	outline:none;
}












/*************************************************
Выкл. подчеркивания Bootstap
*************************************************/

a:focus{
	color: inherit;
	text-decoration: none;
}







